import React from 'react';
import {setConfig} from 'react-hot-loader';
import ReactDOM from 'react-dom';
import configureStore, {history} from './store/configureStore';
import Root from './components/Root';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';
import {getAuthToken, setAuthToken} from './services/auth';

const store = configureStore();
const query = new URLSearchParams(window.location.search);
const token = query.get('token')

if (token) {
  setAuthToken(token);
  window.location.href = "/";
}

axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAuthToken();

setConfig({
  showReactDomPatchNotification: false
})

ReactDOM.render(<Root store={store} history={history}/>, document.getElementById('root'));


if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    ReactDOM.render(<NewRoot store={store} history={history}/>, document.getElementById('root'));
  });
}

/*
render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('root')
    );
  });
}

 */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
