// mediatel
import APPCONFIG from 'constants/appConfig';
import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import Cookies from 'universal-cookie';

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("besiUser4")
    ? JSON.parse(window.localStorage.getItem("besiUser4"))
    : {}

export const setUser = user =>
  window.localStorage.setItem("besiUser4", JSON.stringify(user))


export const redirectOnError = (error) => {
  // handle error

  console.error("redirect on redirectOnError");
  console.error(error);

  if (error.response) {
    console.error("error.response");
    console.error(error.response);
  }
  if (error.response.data) {
    console.error("error.response.data");
    console.error(error.response.data);
  }


  if (typeof error.response === "undefined") {
    console.error("error.response is undefined")
    //    console.error("cannot connect to server - is VPN active?")
  } else {
    console.log("error.response is defined");

    if (error.response.status === 401) {
      console.log("error.response.status === 401, should redirict to login");

      var timeoutVar, testUser;
      timeoutVar = setTimeout(function () {
        console.log("settimeout");

        testUser = getUser();

        fakeAuth.logout();

        redirectAndSaveState(error);

        /* if (testUser.ipCheckDone) {
          console.log("ipcheckdone");
          clearTimeout(timeoutVar);
          if (testUser.validIP) {
            console.log("validIP");
            fakeAuth.logout();

            redirectAndSaveState(error);
          }
          else {
            console.log("invalidIP");
          }
        }
        else {
          console.log("ipchecknotdone");
        } */
      }, 200, error);

    }
    else {
      console.error("error status not handled, only 401 redirects:", error.response.status);
    }
  }
}


export const redirectAndSaveState = (error) => {

  const url = APPCONFIG.appBackendHostname + 'login';

  const cookies = new Cookies();
  cookies.set('mySavedUrl', window.location.href);
  // user not in groups
  console.log('goto Login');
  window.location.href = url;
}

export const logout = callback => {
  setUser({})
  callback()
}


/* A fake authentication function */
export const fakeAuth = {
  isAuthenticated: false,

  logout() {
    this.isAuthenticated = false;
  }

};

export const getAuthToken = () => {

  let token = sessionStorage.getItem('authToken');

  if (token) {
    return token;
  }

  return '';


}
export const setAuthToken = (sessionId) => {
  console.log("timerequest setAuthToken",sessionId);
  sessionStorage.setItem('authToken', sessionId);
}


//Private router function
export const PrivateRoute = ({ component: Component, ...rest }) => {

  const resultAlwaysOK = true;

  return (

    <Route
      {...rest}
      render={props => true === resultAlwaysOK ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: APPCONFIG.permissionDeniedPathName, state: { from: props.location } }}
        />

      )}
    />
  );
};
